import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import * as T from "@radix-ui/react-tooltip";
import "core-js/features/array/at";
import dynamic from "next/dynamic";
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from "react-query";
import "../styles/globals.css";

const RouterProgressBar = dynamic(
  () =>
    import("app/components/router_progress_bar").then(
      (m) => m.RouterProgressBar
    ),
  {
    ssr: false,
  }
);

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: any) {
  useEffect(() => {
    hotjar.initialize({id: 5012147, sv: 6})
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <T.Provider>
        <RouterProgressBar />
        <Component {...pageProps} />
        <GoogleTagManager gtmId={`GTM-MKPS6FX5`} />
        <GoogleAnalytics gaId="G-JEHL8BK53Y" />
      </T.Provider>
    </QueryClientProvider>
  );
}
